import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import IconHumansRights from "./icons/humans-rights"
import IconTerritory from "./icons/territory"
import IconLegalDefence from "./icons/legal-defense"

const LegendCountry = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query LegendCountry {
        dataJson(slug: {eq: "legend-country"}) {
          fragment_1
          fragment_2
          fragment_3
        }
      }
    `
  )

  const iconColor = props.iconcolor ? props.iconcolor : `tertiary`
  const dataFragments = data.dataJson

  return (
    <Box
      className="legend-icons-country"
      sx={{
        backgroundColor: `secondary`,
        fontSize: 0,
        p: 2,
        ul: {
          listStyle: `none`,
          m: 0,
          p: 0,
          alignContent: `center`,
          justifyContent: `center`,
        },
        ".single-icon": {
          alignItems: `center`,
          color: `${iconColor}`,
          mr: 3,
          svg: {
            height: 3,
            width: `auto`,
            mr: 1,
          },
        }
      }}
    >
      <Flex
        as={`ul`}
      >
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconTerritory
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_1}</span>
          </Flex>
        </Themed.li>
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconHumansRights
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_2}</span>
          </Flex>
        </Themed.li>
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconLegalDefence
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_3}</span>
          </Flex>
        </Themed.li>
      </Flex>
    </Box>
  )
}

export default LegendCountry