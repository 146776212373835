import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import IconHumansRights from "./icons/humans-rights"
import IconTerritory from "./icons/territory"
import IconLegalDefence from "./icons/legal-defense"

const LegendCountryDesktop = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query LegendCountryDesktop {
        dataJson(slug: {eq: "legend-country-desktop"}) {
          fragment_1
          fragment_2
          fragment_3
        }
      }
    `
  )

  const iconColor = props.iconcolor ? props.iconcolor : `tertiary`
  const dataFragments = data.dataJson

  return (
    <Box
      className="legend-icons-country-desktop"
      sx={{
        fontFamily: `body`,
        fontSize: 1,
        position: `fixed`,
        bottom: 3,
        left: [`0`, `0`, `0`, `32vw`, `32vw`, `32vw`],
        ul: {
          listStyle: `none`,
          m: 0,
          p: 0,
        },
        li: {
          fontSize: 1,
          my:0,
        },
        ".single-icon": {
          alignItems: `center`,
          color: `${iconColor}`,
          mb: 1,
          svg: {
            height: 6,
            width: `auto`,
            mr: 2,
          },
        }
      }}
    >
      <Box as={`ul`}>
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconTerritory
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_1}</span>
          </Flex>
        </Themed.li>
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconHumansRights
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_2}</span>
          </Flex>
        </Themed.li>
        <Themed.li>
          <Flex className={`single-icon`}>
            <IconLegalDefence
              fill={`${iconColor}`}
            />
            <span>{dataFragments.fragment_3}</span>
          </Flex>
        </Themed.li>
      </Box>
    </Box>
  )
}

export default LegendCountryDesktop