import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"
import LayoutFullHeight from "../components/layout-full-height"
import Aside from "../components/aside"
import PathGuatemalaDepartments from "../components/path-guatemala-departments"
import PathHondurasDepartments from "../components/path-honduras-departments"
import LegendCountry from "../components/legend-country"
import LegendCountryDesktop from "../components/legend-country-desktop"
import { useMediaQuery } from 'react-responsive'
import Seo from "../components/seo"

const Country = ({ data }) => {

  const country = data.countriesJson
  const allDepartments = data.countriesJson.departments
  const [departments, singleDepartment] = useState([])
  const iconsLegendColor = ( country.slug === `hondures` ) ? `honduras` : `guatemala`

  function selectedDepartment( space ) {
    singleDepartment(
      allDepartments.filter( single =>
        single.slug === space
      )
    )
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  return (
    <LayoutFullHeight>
      <Seo
        title={country.title}
        description={country.description}
      />
      <Aside>
        <Box
          as={`div`}
          className={`text-container`}
          sx={{
            h1: {
              color: `${iconsLegendColor}`,
            },
            p: {
              fontSize: 1,
              mb: 3,
            },
            ".country-name": {
              fontFamily: `body`,
              fontSize: 0,
            },
            ".button": {
              color: `secondary`,
              borderBottom: `none`,
              borderRadius: `5px`,
              backgroundColor: `${iconsLegendColor}`,
              cursor: `pointer`,
              display: `inline-block`,
              fontFamily: `body`,
              fontSize: 1,
              mt: [0, 0, 0, 0, 0, 1],
              mb: 2,
              px: 3,
              py: 2,
              textDecoration: `none`,
              ":hover": {
                color: `${iconsLegendColor}`,
                borderBottom: `none`,
                backgroundColor: `secondary`,
              },
            }
          }}
        >
        {departments.length > 0 ?
          departments.map((department, index) => (
            <Box key={index}>
              <span className="country-name">{data.countriesJson.title}</span>
              <Themed.h1>{department.name}</Themed.h1>
              <div dangerouslySetInnerHTML={{ __html: department.content}} />
            </Box>
          )): 
          <Box>
            <Themed.h1>{data.countriesJson.title}</Themed.h1>
            <div dangerouslySetInnerHTML={{ __html: data.countriesJson.content}} />
            <Link to={`/${data.countriesJson.linktoarchive}`} className="button">
            {data.countriesJson.textlinktoarchive}
            </Link>
          </Box>
        }
        </Box>
      </Aside>
      <Box
        as={`main`}
        className="map-container"
      >
        {country.slug === `hondures`
          ? <PathHondurasDepartments departmentFromMainComponent={selectedDepartment} />
          : <PathGuatemalaDepartments departmentFromMainComponent={selectedDepartment} />
        }
        {isTabletOrMobile &&
          <LegendCountry iconcolor={iconsLegendColor} />
        }
        {isDesktopOrLaptop &&
          <LegendCountryDesktop iconcolor={iconsLegendColor} />
        }
      </Box>
    </LayoutFullHeight>
  )
}

export default Country

export const query = graphql`
  query($slug: String!) {
    countriesJson(slug: {eq: $slug}) {
      title
      content
      description
      slug
      linktoarchive
      textlinktoarchive
      departments {
        name
        content
        slug
      }
    }
  }
`
